<template>
  <footer class="relative z-50">
    <div class="px-4 flex justify-center sm:justify-end text-sm sm:text-base">
      <a
        class="h-full py-4 px-4 cursor-pointer font-medium"
        href="https://medium.com/@a.howitt/49caf8399fee"
      >
        WTF
      </a>
      <a
        class="h-full py-4 px-4 cursor-pointer font-medium"
        href="https://github.com/jannikluhn/knockoff"
      >
        Github
      </a>
      <a
        class="h-full py-4 px-4 cursor-pointer font-medium"
        href="https://twitter.com/knockoffNFT"
      >
        Twitter
      </a>
      <a
        class="h-full py-4 px-4 cursor-pointer font-medium"
        href="https://discord.gg/fu7B4jRECB"
      >
        Discord
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
